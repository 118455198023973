import { Observable } from 'rxjs'
import http from '../adapters/http'

export default {
  feature(params, token) {
    return new Observable(
      (observer) =>
        new http.GET(
          observer,
          `${process.env.HUB_SERVICE_URL}/api/core/v1/client_configs/menu`,
          params,
          { Authorization: token }
        )
    )
  },
}
