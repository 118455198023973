import { Observable } from 'rxjs'
import http from '../adapters/http'
import { validTokenCookie } from '~/utils/general'

export default {
  getService(baseUrl, params, headers) {
    if (headers.Authorization) headers.Authorization = validTokenCookie()
    baseUrl = window.$nuxt.$auth.user
      ? baseUrl.replace(
          'v1/',
          'v1/' + window.$nuxt.$auth.user.organization_id + '/'
        )
      : baseUrl
    return new Observable(
      (observer) => new http.GET(observer, baseUrl, params, headers)
    )
  },
  putService(baseUrl, params, headers) {
    if (headers.Authorization) headers.Authorization = validTokenCookie()
    baseUrl = window.$nuxt.$auth.user
      ? baseUrl.replace(
          'v1/',
          'v1/' + window.$nuxt.$auth.user.organization_id + '/'
        )
      : baseUrl
    return new Observable(
      (observer) => new http.PUT(observer, baseUrl, params, headers)
    )
  },
  postService(baseUrl, params, headers) {
    if (headers.Authorization) headers.Authorization = validTokenCookie()
    baseUrl = window.$nuxt.$auth.user
      ? baseUrl.replace(
          'v1/',
          'v1/' + window.$nuxt.$auth.user.organization_id + '/'
        )
      : baseUrl
    return new Observable(
      (observer) => new http.POST(observer, baseUrl, params, headers)
    )
  },
  deleteService(baseUrl, params, headers) {
    baseUrl = window.$nuxt.$auth.user
      ? baseUrl.replace(
          'v1/',
          'v1/' + window.$nuxt.$auth.user.organization_id + '/'
        )
      : baseUrl
    return new Observable(
      (observer) => new http.DELETE(observer, baseUrl, params, headers)
    )
  },
}
