import { Observable } from 'rxjs'
import http from '../adapters/http'

export default {
  getAll(params, headers) {
    const url = `${process.env.HUB_SERVICE_URL}/api/v1/${window.$nuxt.$auth.user.organization_id}/packs`

    return new Observable(
      (observer) => new http.GET(observer, url, params, headers)
    )
  },
}
