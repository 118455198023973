import { Observable } from 'rxjs'
import http from '../adapters/http'
import { validTokenCookie } from '~/utils/general'

export default {
  getService(baseUrl, params = {}, headers = {}) {
    if (headers.Authorization) headers.Authorization = validTokenCookie()
    return new Observable(
      (observer) => new http.GET(observer, baseUrl, params, headers)
    )
  },
}
