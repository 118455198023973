import { Observable } from 'rxjs'
import http from '../adapters/http'

export default {
  downtime(params, token) {
    return new Observable(
      (observer) =>
        new http.GET(
          observer,
          `${process.env.HUB_URL}/web/api/downtime`,
          params,
          { Authorization: token }
        )
    )
  },
}
