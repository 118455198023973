import { Observable } from 'rxjs'
import http from '../adapters/http'
import { validTokenCookie } from '~/utils/general'

export default {
  postOrder(params, headers) {
    if (headers.Authorization) headers.Authorization = validTokenCookie()
    const url = `${process.env.HUB_SERVICE_URL}/api/v1/${window.$nuxt.$auth.user.organization_id}/invoices`
    return new Observable(
      (observer) => new http.POST(observer, url, params, headers)
    )
  },
}
