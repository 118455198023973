import _ from 'lodash-core'
import requests from '../requests'

export const state = () => ({
  isLoaded: false,
  loadGraph: false,
  dateRangeInput: [],
  title: 'Payment and subscription',
  activeMenu: 'finances',
  messageSentData: {
    type: 'line',
    name: 'Messages Sent',
    data: [],
    color: '#08c6ad',
    lineWidth: 5,
    marker: {
      symbol: 'circle',
    },
  },
  messageDeliveredData: {
    type: 'line',
    name: 'Messages Delivered',
    data: [],
    color: '#435dd9',
    lineWidth: 2,
    marker: {
      symbol: 'circle',
    },
  },
  messageReceivedData: {
    type: 'line',
    name: 'Messages Received',
    data: [],
    color: '#a041c8',
    lineWidth: 2,
    marker: {
      symbol: 'circle',
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      valuePrefix: '',
      valueSuffix: '',
    },
  },
  amountSpentData: {
    type: 'line',
    yAxis: 1,
    name: 'Amount Spent',
    data: [],
    color: '#ff7c48',
    lineWidth: 2,
    marker: {
      symbol: 'circle',
    },
    dataLabels: {
      enabled: true,
      format: 'Rp. {y}',
    },
    tooltip: {
      valuePrefix: 'Rp. ',
      valueSuffix: '',
    },
  },
  numberInput: '',
  numberOptions: [],
  countryInput: '',
  countryOptions: [],
  messageTypeInput: '',
  messageTypeOptions: [],
  timeStartInput: '',
  timeEndInput: '',
})

export const mutations = {
  SET_TO_LOAD(state) {
    state.isLoaded = true
  },
  SET_DATE_RANGE_INPUT(state, dateRange) {
    state.dateRangeInput = dateRange
  },
  LOAD_GRAPH(state, load) {
    state.loadGraph = load
  },
  SET_MESSAGE_SENT_DATA(state, datas) {
    state.messageSentData.data = datas
  },
  SET_MESSAGE_DELIVERED_DATA(state, datas) {
    state.messageDeliveredData.data = datas
  },
  SET_MESSAGE_RECEIVED_DATA(state, datas) {
    state.messageReceivedData.data = datas
  },
  SET_AMOUNT_SPENT_DATA(state, datas) {
    state.amountSpentData.data = datas
  },
  SET_NUMBER(state, number) {
    state.numberInput = number
  },
  SET_NUMBER_OPTIONS(state, numbers) {
    state.numberOptions = numbers
  },
  SET_COUNTRY(state, country) {
    state.countryInput = country
  },
  SET_COUNTRY_OPTIONS(state, countries) {
    state.countryOptions = countries
  },
  SET_MESSAGE_TYPE(state, messageType) {
    state.messageTypeInput = messageType
  },
  SET_MESSAGE_TYPE_OPTIONS(state, messageTypes) {
    state.messageTypeOptions = messageTypes
  },
  SET_TIME_START(state, unixTime) {
    state.timeStartInput = unixTime
  },
  SET_TIME_END(state, unixTime) {
    state.timeEndInput = unixTime
  },
}

export const actions = {
  getAnalytic({ commit, state }, params) {
    commit('LOAD_GRAPH', true)
    requests.whatsapp
      .analytic(
        `${process.env.HUB_SERVICE_URL}/api/v1/whatsapp/business_management/analytics`,
        params.data,
        {
          Authorization: this.$auth.getToken('hub'),
        }
      )
      .subscribe(
        (response) => {
          const sendData = []
          const deliveredData = []
          const receivedData = []
          const costData = []
          _.forEach(response.data.data_points, (data) => {
            if (params.data.product_types.includes(100) === true) {
              if (state.messageTypeInput.key === '0') {
                receivedData.push([new Date(data.start * 1000).getTime(), 0])
              } else {
                receivedData.push([
                  new Date(data.start * 1000).getTime(),
                  data.received,
                ])
              }
            } else {
              costData.push([new Date(data.start * 1000).getTime(), data.cost])
              sendData.push([new Date(data.start * 1000).getTime(), data.sent])
              deliveredData.push([
                new Date(data.start * 1000).getTime(),
                data.delivered,
              ])
            }
          })
          if (params.data.product_types.includes(100) === true) {
            commit('SET_MESSAGE_RECEIVED_DATA', receivedData)
          } else {
            commit('SET_MESSAGE_SENT_DATA', sendData)
            commit('SET_MESSAGE_DELIVERED_DATA', deliveredData)
            commit('SET_AMOUNT_SPENT_DATA', costData)
          }
        },
        (errors) => {},
        () => {
          commit('LOAD_GRAPH', false)
        }
      )
  },
  getPhoneNumbers({ commit, dispatch }, params) {
    requests.whatsapp
      .phoneNumbers(
        `${process.env.HUB_SERVICE_URL}/api/v1/whatsapp/business_management/phone_numbers`,
        {},
        {
          Authorization: this.$auth.getToken('hub'),
        }
      )
      .subscribe(
        (response) => {
          const numberOptions = []
          _.forEach(response.data, (data) => {
            if (
              data.quality_rating === 'GREEN' ||
              data.quality_rating === 'UNKNOWN'
            ) {
              numberOptions.push({
                key: data.phone_number,
                value: data.display_phone_number,
              })
            }
          })
          commit('SET_NUMBER_OPTIONS', numberOptions)
          commit('SET_NUMBER', numberOptions[0])
        },
        (errors) => {},
        () => {
          dispatch('updateGraphData')
        }
      )
  },
  changeNumber({ commit, dispatch }, number) {
    commit('SET_NUMBER', number)
    dispatch('updateGraphData')
  },
  changeCountry({ commit, dispatch }, country) {
    commit('SET_COUNTRY', country)
    dispatch('updateGraphData')
  },
  setCountryOptions({ commit }, countries) {
    commit('SET_COUNTRY_OPTIONS', countries)
  },

  changeMessageType({ commit, dispatch }, messageType) {
    commit('SET_MESSAGE_TYPE', messageType)
    dispatch('updateGraphData')
  },
  setMessageTypeOptions({ commit }, messageTypes) {
    commit('SET_MESSAGE_TYPE_OPTIONS', messageTypes)
  },
  changeTimeStart({ commit }, unixTime) {
    commit('SET_TIME_START', unixTime)
  },
  changeTimeEnd({ commit }, unixTime) {
    commit('SET_TIME_END', unixTime)
  },
  updateGraphData({ dispatch, state }) {
    const params = {
      time_start: state.timeStartInput,
      time_end: state.timeEndInput,
      phone_numbers: [state.numberInput.key],
      granularity: 'DAY',
      product_types: [state.messageTypeInput.key],
    }
    const paramsReceive = {
      time_start: state.timeStartInput,
      time_end: state.timeEndInput,
      phone_numbers: [state.numberInput.key],
      granularity: 'DAY',
      product_types: [100],
    }
    dispatch('getAnalytic', {
      serviceUrl: process.env.HUB_SERVICE_URL,
      data: params,
    })
    dispatch('getAnalytic', {
      serviceUrl: process.env.HUB_SERVICE_URL,
      data: paramsReceive,
    })
  },
  setDefaultData({ commit }, params) {
    commit('SET_MESSAGE_TYPE', params.messageType)
    commit('SET_COUNTRY', params.country)
  },
}

export const getters = {
  totalMessageSent: (state) => {
    return _.sumBy(state.messageSentData.data, function (o) {
      return o[1]
    })
  },
  totalMessageDelivered: (state) => {
    return _.sumBy(state.messageDeliveredData.data, function (o) {
      return o[1]
    })
  },
  totalMessageReceived: (state) => {
    return _.sumBy(state.messageReceivedData.data, function (o) {
      return o[1]
    })
  },
  totalAmountSpent: (state) => {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    })
    return formatter.format(
      _.sumBy(state.amountSpentData.data, function (o) {
        return o[1]
      })
    )
  },
}
