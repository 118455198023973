import { Observable } from 'rxjs'
import http from '../adapters/http'

export default {
  directSignIn(params) {
    const url = `${process.env.HUB_SERVICE_URL}/oauth/direct_sign_in`
    return new Observable(
      (observer) => new http.POST(observer, url, params, '')
    )
  },
  signIn(baseUrl, params) {
    const url = `${process.env.HUB_URL}/web/api/oauth/sign_in`
    return new Observable(
      (observer) => new http.POST(observer, url, params, '')
    )
  },
}
