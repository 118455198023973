import { getField, updateField } from 'vuex-map-fields'
import requests from '../requests'
import {
  AGENTS_ALLOCATION,
  BROADCAST_URL,
  ONGOING_CHAT,
  ROOMS_URL,
  ORGANIZATION_SETTINGS_URL,
} from '~/assets/variables/endpoints'

export const state = () => ({
  agentList: [
    {
      id: 1,
      name: 'Admin Qontak',
      email: 'admin@qontak.com',
      access_level: 'Agent',
      division: 'General',
      online: true,
    },
    {
      id: 2,
      name: 'Alan Budikusuma',
      email: 'alan@qontak.com',
      access_level: 'Supervisor',
      division: '',
      online: true,
    },
    {
      id: 3,
      name: 'Alam YBS',
      email: 'alam@qontak.com',
      access_level: 'Agent',
      division: 'Qontak Support',
      online: false,
    },
    {
      id: 4,
      name: 'Burhanudin Hakim',
      email: 'burhanudin@qontak.com',
      access_level: 'Supervisor',
      division: '',
      online: true,
    },
  ],
  divisionList: [
    {
      id: 1,
      division: 'General',
      isDefault: true,
    },
    {
      id: 2,
      division: 'Qontak Support',
      isDefault: false,
    },
    {
      id: 3,
      division: 'Qontak Technical',
      isDefault: false,
    },
    {
      id: 4,
      division: 'Finance',
      isDefault: false,
    },
  ],
  allocation: {
    agentTakeover: false,
    agentCanAssign: false,
    autoAllocation: false,
    customAllocation: false,
    webhookURL: '',
  },
  userAbleTo: {
    broadcast: false,
  },
  userDetail: {
    avatar: '',
    first_name: 'Qontak',
    last_name: 'Admin',
    email: 'email@email.com',
    isSupervisor: false,
    role_id: {
      id: 1,
      name: 'Admin',
    },
    division_id: {
      id: 1,
      name: 'General',
    },
  },
  roleLists: [
    {
      id: 1,
      name: 'Admin',
    },
    {
      id: 2,
      name: 'Agent',
    },
  ],
  divisionLists: [
    {
      id: 1,
      name: 'General',
    },
    {
      id: 2,
      name: 'Lead',
    },
  ],
  limitChat: {
    enabled: false,
    max: '',
  },
  agentIdleRuleAssignment: {
    period: 5,
    enabled: false,
  },
})

export const getters = {
  getField,
  getUserAbleTo(state) {
    return state.userAbleTo
  },
}

export const actions = {
  updateAgentTakeOver({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      requests.whatsapp
        .putService(
          `${AGENTS_ALLOCATION}/agent_can_takeover`,
          { enabled: params.enabled_takeover },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  updateAgentAutoAllocation({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      requests.whatsapp
        .putService(
          `${AGENTS_ALLOCATION}/automatic`,
          { enabled: params.enabled_auto_allocation },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  updateAgentCustomAllocation({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      requests.whatsapp
        .putService(
          `${AGENTS_ALLOCATION}/custom`,
          { enabled: params.enabled_custom_allocation, url: params.url },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  updateAgentCanBroadcastSetting({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      requests.whatsapp
        .putService(`${BROADCAST_URL}/agent_can_send_broadcast`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  updateAgentCanAssign({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })

      requests.whatsapp
        .putService(`${ROOMS_URL}/agent_assign_another_agent`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  updateChatLimit({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })

      const enabled = params > 0
      const data = {
        enabled,
        max: enabled ? params : 5, // 5 is current default value
      }

      requests.whatsapp
        .putService(`${ONGOING_CHAT}/limit`, data, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              {
                title: 'Conversation limit saved',
                variant: 'success',
              },
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              {
                title: err?.error?.messages?.[0],
                variant: 'error',
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  updateIdleRule({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${ORGANIZATION_SETTINGS_URL}/agent_idle_rule`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_AGENT_ALLOCATION(state, payload) {
    state.allocation = payload
  },
  UPDATE_AGENT_CAN_BROADCAST(state, payload) {
    state.userAbleTo.broadcast = payload
  },
  UPDATE_AGENT_CHAT_LIMIT(state, payload) {
    state.limitChat = payload
  },
  UPDATE_AGENT_IDLE_RULE_ASSIGNMENT(state, payload) {
    state.agentIdleRuleAssignment = {
      period: payload?.period || 5,
      enabled: payload?.enabled || false,
    }
  },
}
