import session from './session'
import settings from './settings'
import downtime from './downtime'
import whatsapp from './whatsapp'
import packs from './packs'
import orders from './orders'
import invoices from './invoices'
import channels from './channels'
import webchat from './webchat'

export default {
  session,
  whatsapp,
  packs,
  orders,
  invoices,
  settings,
  downtime,
  channels,
  webchat,
}
